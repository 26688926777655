/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {lazy, Suspense} from "react";
import {HelmetProvider} from 'react-helmet-async';
import {
  QueryClient,
  QueryClientProvider,
  useQueryErrorResetBoundary
} from "react-query";
import {BrowserRouter as Router, Route, Switch, Redirect, useLocation} from 'react-router-dom';
import {ErrorBoundary} from "react-error-boundary";
import {ReactQueryDevtools} from "react-query/devtools";
import "./App.sass";

// Elements
import {Indicator} from "./components/elements/Indicator";
// Components
import {NotFoundSection} from "./components/components/NotFoundSection";
import {ErrorOccurredSection} from "./components/components/ErrorOccurredSection";
import {useURLSearchParams} from "./hooks/useURLSearchParams";
import {useGetLineUser} from "./hooks/useGetLineUser";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      refetchOnReconnect: false,
      retry: false,
    },
  },
});

const InputPage = lazy(() => import('./pages/input'));
const PrPage = lazy(() => import('./pages/pr'));
const AppliedPage = lazy(() => import('./pages/applied'));
const MeetingPage = lazy(() => import('./pages/meeting'));
const CameratestPage = lazy(() => import('./pages/cameratest'));
const ThanksPage = lazy(() => import('./pages/thanks'));
const ThanksMeetingPage = lazy(() => import('./pages/thanks_meeting'));
const ThanksCameraPage = lazy(() => import('./pages/thanks_camera'));
const FinalPage = lazy(() => import('./pages/final'));
const ClosedPage = lazy(() => import('./pages/closed'));

// Future Pages
// const AnnouncePage = lazy(() => import('./pages/announce'));
// const MeetingPage = lazy(() => import('./pages/meeting'));
// const AdditionalQuestionPage = lazy(() => import('./pages/offline'));

const App = () => {
  const {reset} = useQueryErrorResetBoundary();
  return (
    <HelmetProvider>
      <div className="App">
        <QueryClientProvider client={queryClient}>
          <Suspense fallback={<Indicator active/>}>
            <ErrorBoundary onReset={reset} fallbackRender={({resetErrorBoundary}) => <ErrorOccurredSection
              tryAgain={resetErrorBoundary}/>}>
              <QueryClientProvider client={queryClient}>
                <Router>
                  <Routing/>
                </Router>
              </QueryClientProvider>
            </ErrorBoundary>
          </Suspense>
          <ReactQueryDevtools initialIsOpen/>
        </QueryClientProvider>
      </div>
    </HelmetProvider>
  );
};

function Routing() {
  const location = useLocation();
  const params = useURLSearchParams();
  const redirectTo = params.get('liff.state');
  const {data: user} = useGetLineUser();
  console.log(`Welcome to ${user?.display_name} !`);
  if (redirectTo) {
    return <Redirect to={redirectTo}/>;
  }
  return (
    <div className="FormPage">
      <Switch>
        <Route path="/" exact children={<InputPage/>}/>
        <Route path="/closed" exact children={<ClosedPage/>}/>
        <Route path="/404" children={<NotFoundSection/>}/>
        <Route path="/pr" children={<PrPage/>}/>
        <Route path="/entry" children={<InputPage/>}/>
        <Route path="/applied" children={<AppliedPage/>}/>
        <Route path="/r1" children={<ThanksPage/>}/>
        <Route path="/r2" children={<ThanksMeetingPage/>}/>
        <Route path="/r3" children={<ThanksCameraPage/>}/>
        <Route path="/meeting" children={<MeetingPage/>}/>
        <Route path="/cameratest" children={<CameratestPage/>}/>
        <Route path="/final" children={<FinalPage/>}/>
        {/* Future Pages */}
        {/*<Route path="/3rd" children={<AdditionalQuestionPage />} />*/}
        {/*<Route path="/4th" children={<AnnouncePage />} />*/}
        <Redirect to={{...location, pathname: `/404`}}/>
      </Switch>
    </div>
  );
}


export default App;
